.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: transparent !important; /* Makes the card-back-section iframe background transparent */
  overflow: hidden;
}

.minimap-container {
  width: 200px;
  height: 150px;
  position: relative;
}

.minimap-container .react-flow__minimap {
  width: 100% !important;
  height: 100% !important;
}

.label-no-margin {
  margin-bottom: 0 !important;
}

.settings-footer {
  margin: 0 !important;
}

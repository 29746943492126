.privacy-policy-container {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  height: 100vh; /* Make the container take the full viewport height */
  display: flex;
  flex-direction: column;
}

.privacy-policy-header {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center horizontally */
  margin-bottom: 20px;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.logo {
  max-width: 45px; /* Adjust size as needed */
  margin-right: 10px; /* Space between logo and text */
}

h1 {
  font-size: 2.5em;
  margin: 0; /* Remove default margins */
  color: #2c3e50;
}

.privacy-policy-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex-grow: 1; /* Allow the content to grow and take the remaining space */
  overflow-y: auto; /* Enable scrolling */
  margin-bottom: 20px; /* Add margin at the bottom for spacing */
}

.scroll-container {
  padding: 10px;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #2c3e50;
}

p {
  margin-bottom: 10px;
}

strong {
  color: #2c3e50;
}

.policy-list {
  list-style-type: decimal;
}

.policy-list ol {
  list-style-type: lower-alpha;
  padding-left: 20px;
}

.policy-list ol ol {
  list-style-type: lower-roman;
}
